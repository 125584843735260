<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">User</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Data User</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="User"
          class="mr-2"
          @click="onAddData"
        />
      </div>
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
        @sort="onSort($event)"
      >
        <template #columns>
          <Column
            field="name"
            header="Nama"
            style="min-width: 15rem"
            sortable
            :filter-match-mode-options="containModesOption"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column field="username" header="Username" />
          <Column field="email" header="Email" />
          <Column field="phone" header="No. Telp." />
          <Column
            header="Status"
            style="min-width: 6rem"
            header-style="justify-content: center"
          >
            <template #body="slotProps">
              <div style="width: 100%; text-align: center">
                <Tag
                  v-if="slotProps.data.status === 'active'"
                  severity="success"
                  value="Aktif"
                ></Tag>
                <Tag
                  v-else
                  :style="{ background: 'grey' }"
                  value="Tidak Aktif"
                ></Tag>
              </div>
            </template>
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="editMode ? 'Edit User' : 'Tambah User'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
      :maximizable="true"
    >
      <form-user
        :item="form"
        :loading="isLoadingSave"
        :user="user"
        :editMode="editMode"
        :list-role="list_role"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Hapus User"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >User <strong>{{ form.name }}</strong> akan dihapus. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteUser"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import RoleService from '@/services/RoleService'
import FormUser from '@/components/master/FormUser'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'
import Tag from 'primevue/tag'

export default {
  name: 'User',
  components: {
    FormUser,
    FxTable,
    Hotkey,
    Tag,
  },
  data() {
    return {
      userService: null,
      roleService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      editMode: false,
      items: [],
      list_role: [],
      form: {},
      user: [],
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'name',
        sortOrder: '1',
        filters: null,
      },
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      containModesOption: [
        { label: 'Contains', value: FilterMatchMode.CONTAINS },
      ],
      equalModesOption: [{ label: 'Equals', value: FilterMatchMode.EQUALS }],
    }
  },
  created() {
    this.userService = new UserService()
    this.roleService = new RoleService()
  },
  async mounted() {
    const roleService = new RoleService()
    await roleService
      .get()
      .then((res) => {
        this.list_role = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Role', this)
      })

    this.loadData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.userService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data
          this.totalRecords = res.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data user', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onSort(e) {
      this.options.value = e
      this.loadData()
    },
    onEditData(data) {
      this.editMode = true
      this.form = Object.assign({}, data)
      this.form.password = ''
      this.form.roles = this.form.roles.map((item) => ({
        ...item,
        descriptions: this.capitalizeFirstLetter(item.name),
      }))

      this.dialog = true
    },
    onAddData() {
      this.editMode = false
      this.form = {}
      this.form.id = 0
      this.form.name = ''
      this.form.password = ''
      this.form.roles = [ this.list_role[1] ]
      this.form.status = 'active'
      this.dialog = true
    },
    capitalizeFirstLetter(sentence) {
      return sentence.charAt(0).toUpperCase() + sentence.slice(1)
    },
    onSaveData(data) {
      this.isLoadingSave = true
      const item = {}
      item.name = data.name
      item.username = data.username
      item.email = data.email
      item.phone = data.phone
      item.status = data.status
      if (this.form.password) {
        if (this.form.password !== '') {
          item.password = this.form.password
        }
      }
      if (this.editMode == false) {
        // const item = Object.assign({}, data)
        this.userService
          .add(item)
          .then((res) => {
            if (res.data.status === 1) {
              const form = {
                user_id: res.data.data.id,
                roles: this.form.roles.map((item) => item.id),
              }
              this.roleService
                .assign(form)
                .then(() => {
                  this.loadData()
                  this.$toast.add({
                    severity: 'success',
                    summary: 'Data user',
                    detail: 'Data berhasil disimpan.',
                    life: 3000,
                  })
                  this.dialog = false
                })
                .catch((err) => {
                  errorHandler(err, 'Data user', this)
                })
                .finally(() => (this.isLoadingSave = false))
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data user', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        this.userService
          .update(data.id, item)
          .then((res) => {
            if (res.data.status === 1) {
              const form = {
                user_id: res.data.data.id,
                roles: this.form.roles.map((item) => item.id),
              }
              this.roleService
                .assign(form)
                .then(() => {
                  this.loadData()
                  this.$toast.add({
                    severity: 'success',
                    summary: 'Data user',
                    detail: 'Data berhasil disimpan.',
                    life: 3000,
                  })
                  this.dialog = false
                })
                .catch((err) => {
                  errorHandler(err, 'Data user', this)
                })
                .finally(() => (this.isLoadingSave = false))
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data user', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteUser() {
      this.userService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 1) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data User',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data User', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
    /* onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    }, */
  },
}
</script>
